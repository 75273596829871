import React, { useEffect, useRef, useState } from 'react';
import * as API from '../api/index';
import { useLocation } from 'react-router';
import { IMG } from '../api/constant';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const SharableLink = () => {
  const [cardData, setCardData] = useState('');
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isLinkExpired, setLinkExpired] = useState(false);
  const audioPlayerRef = useRef(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cardKey = searchParams.get('key');

  useEffect(() => {
    get_card_by_id(cardKey);
  }, [cardKey]);

  // useEffect(() => {
  //   if (audioPlayerRef.current && audioPlayerRef.current.audioEl) {
  //     audioPlayerRef.current.audioEl.current.muted = true;
  //   }
  // }, []);

  useEffect(() => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audio.current.pause();
    }
  }, [cardData]);

  const get_card_by_id = async (cardKey) => {
    try {
      const response = await API.get_greeting_card_id(cardKey);
      if (response.status === 200) {
        setCardData(response?.data);
      } else {
        console.log('Error: ', response?.data?.error === 'Link is expired.');
        if (response?.data?.msg === 'Link is expired') {
          setLinkExpired(true);
        }
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleDownloadClick = (downloadLink, name) => {
    fetch(downloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        let formattedName = name.replace(/^\s+|\s+$/g, '').replace(/\s+/g, '-');
        formattedName += '.mp3';
        link.setAttribute('download', formattedName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error downloading file:', error));
  };

  const inputRef = useRef(null);
  const [active, setActive] = useState(false);
  const [buttonText, setButtonText] = useState('Copy');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(inputRef.current.value);
      setActive(true);
      setButtonText('Copied');
      setTimeout(() => {
        setActive(false);
        setButtonText('Copy');
      }, 3000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const shareOnSocialMedia = (platform, title, description) => {
    const shareUrl = generateShareableLink();

    switch (platform) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(description)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(description)}`, '_blank');
        break;
      case 'telegram':
        window.open(`https://t.me/share/url?url=${encodeURIComponent(shareUrl)}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(shareUrl)}`, '_blank');
        break;
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`,
          '_blank'
        );
        break;
      default:
        break;
    }
  };

  const generateShareableLink = () => {
    const url = new URL(window.location.href);
    url.pathname = '/sharable-link';
    url.searchParams.set('key', cardKey);
    return url.href;
  };

  if (isLinkExpired) {
    return (
      <div className='expired-message-container'>
        <div className='expired-message'>
          <h1>Link Expired</h1>
          <p>The link you are trying to access has expired. Please contact the sender for a new link.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='sharable-box w-100'>
        <div className='sharable-container'>
          <div className='sharable-image-container'>
            <img src={cardData?.image_logo ? `${IMG}${cardData.image_logo}` : `${IMG}${cardData?.songimage}`} alt='music-img' className='img-fluid' />
          </div>
          <div className='text-buttons-container'>
            <p className='sharable-description text-center'>{cardData?.title ?? cardData?.songname}</p>
            <div className='sharable-buttons flex-wrap'>
              <button className='btn btn-primary view-modal d-flex align-items-center justify-content-center' onClick={() => setPopupVisible(true)}>
                <i className='bi bi-share text-white'></i>
                <span>Share</span>
              </button>
              <button
                className='btn btn-success view-modal d-flex align-items-center justify-content-center'
                onClick={(e) => handleDownloadClick(IMG + cardData.combined, cardData?.title ?? cardData.songname)}
              >
                <i className='bi bi-download'></i>
                <span>Download</span>
              </button>
            </div>
          </div>
        </div>
        <div className='text-end' style={{ marginBottom: '10px', fontSize: '18px' }}>
          Powered by <a href='/'>Wrapped In Music</a>
        </div>
        <div className='row'>
          <div className='col-md-12 historyPlay w-100'>
            <AudioPlayer ref={audioPlayerRef} autoPlay={false} src={IMG + cardData?.combined} />
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className='social-popup show'>
          <header>
            <span>Share Link</span>
            <div className='close' onClick={() => setPopupVisible(false)}>
              <i className='bi bi-x'></i>
            </div>
          </header>

          <div className='content'>
            <p>Share this link via</p>

            <ul className='icons'>
              <li>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnSocialMedia('facebook', cardData?.title ?? cardData?.songname, '');
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-facebook'></i>
                </a>
              </li>
              <li>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnSocialMedia('twitter', cardData?.title ?? cardData?.songname, '');
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-twitter'></i>
                </a>
              </li>
              <li>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnSocialMedia('telegram', cardData?.title ?? cardData?.songname, '');
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-telegram'></i>
                </a>
              </li>
              <li>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnSocialMedia('whatsapp', cardData?.title ?? cardData?.songname, '');
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-whatsapp'></i>
                </a>
              </li>
              <li>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnSocialMedia('linkedin', cardData?.title ?? cardData?.songname, '');
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-linkedin'></i>
                </a>
              </li>
            </ul>

            <p>Or copy link</p>
            <div className='field'>
              <i className='bi bi-link-45deg'></i>
              <input ref={inputRef} type='text' readOnly value={generateShareableLink()} />
              <button className={active ? 'active' : ''} onClick={handleCopy}>
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SharableLink;
