import React from 'react';
import bannerImg from '../../src/images/img2.png';
import img3 from '../../src/images/img3.png';
import img1 from '../../src/images/img1.png';
import { useNavigate } from 'react-router';

const Header = ({ isOpen, shouldDisplaySidebar }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
    window.location.reload();
  };
  return (
    <>
      <div className={isOpen ? 'ms_headerPost ms_header' : 'ms_header'} style={!shouldDisplaySidebar ? { left: '0px' } : {}}>
        <div className='ms_top_left'>
          <div className='imgbanner'>
            {/* <h1>WRAPPED IN MUSIC</h1> */}
            <img src={bannerImg} className='header-img1' alt='' style={{ width: 100 }} />
            {/* <img src={img3} alt='' style={{ width: ' 45% ' }} /> */}
            <a href='/' className='img-link w-100 text-center' onClick={() => handleClick()}>
              <img src={img3} alt='' className='header-img2' style={{ width: '500px', maxWidth: '100%' }} />
            </a>
            <img src={img1} alt='' className='header-img3' style={{ width: 100 }} />
          </div>
          <div className='ms_top_trend d-none'>
            <span>
              <a href='#' className='ms_color'>
                LOGO
              </a>
            </span>
            <span className='top_marquee'>
              <a href='#'></a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
