import React from "react";

const Payment = () => {
  return (
    <>
      <h3>Error</h3>
    </>
  );
};

export default Payment;
