import React, { useEffect, useRef, useState } from 'react';
import { Await, useNavigate } from 'react-router';
import EditProfile from './EditProfile';
import * as API from '../api/index';
import ChangesPassword from './ChangesPassword';
import { Link } from 'react-router-dom';
import DownloadMusic from './DownloadMusic';
import { IMG } from '../api/constant';
import DownloadLink from 'react-download-link';
import AudioPlayer from 'react-h5-audio-player';
import { MESSAGE } from '../schemas/Validation';
// import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';
import ReactCrop from 'react-image-crop';

const initialValues = {
  name: '',
  email: '',
  city: '',
  state: '',
  country: '',
  address: '',
  address1: '',
  phone: '',
};

const MyAccount = ({ setIsLogin }) => {
  const [formData, setFormData] = useState(initialValues);
  const [allCountryData, setAllCountryData] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [allCityData, setAllCityData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const navigate = useNavigate();
  const [getUserData, setGetUserData] = useState('');
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('');
  const [cityData, setCityData] = useState('');
  const [histoySong, setHistoySong] = useState('');
  const [currentPlay, setCurrentPlay] = useState('');

  // New Code add greting card ...
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editId, setEditId] = useState('');
  const [title, setTitle] = useState('');
  const [imgSrc, setImgSrc] = useState('');

  // State for confirmation modal
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);

  useEffect(() => {
    userDataGetById();
  }, []);

  const [imageSrc, setImageSrc] = useState('https://cdn-icons-png.flaticon.com/512/3237/3237472.png');

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        toast.error('Invalid file type. Please upload an image file.');
        return;
      }

      // // Validate file size (e.g., 5MB limit)
      // const maxSize = 5 * 1024 * 1024; // 5MB
      // if (file.size > maxSize) {
      //   toast.error('File is too large. Please upload an image smaller than 5MB.');
      //   return;
      // }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);

      await uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    const header = localStorage.getItem('_tokenCode');

    const formData = new FormData();
    formData.append('user_image', file);
    formData.append('id', localStorage.getItem('__userId'));

    try {
      const response = await API.getuser_update(formData, header, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.data.success === 1) {
        userDataGetById();
        toast.success(response.data.data.msg);
      } else {
        toast.error(response.data.data.msg);
      }
    } catch (error) {
      toast.error('An error occurred while updating user data.');
    } finally {
      // setLoading(false);
    }
  };

  const userDataGetById = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const cresponse = await API.allCountry(header);
      setAllCountryData(cresponse.data.data);
      const musicBoxresponse = await API.orderHistroy(localStorage.getItem('__userId'), header);
      setOrderData(musicBoxresponse.data.data || []);
      const response = await API.getuserDataID(localStorage.getItem('__userId'), header);
      setFormData(response.data.data);
      setGetUserData(response.data.data);

      if (response.data.data.country) {
        const state_response = await API.allState(response.data.data.country, header);
        setAllStateData(state_response.data.data);
        const city_response = await API.allCity(response.data.data.state, header);
        setAllCityData(city_response.data.data);
      }
      if (response.data.is_login === false) {
        localStorage.removeItem('_tokenCode');
        localStorage.removeItem('isLogin');
        setIsLogin(localStorage.removeItem('isLogin'));
        if (localStorage.getItem('isLogin') === null) {
          navigate('/login');
        }
      }
    } catch (error) {}
  };

  const logout = () => {
    localStorage.removeItem('_tokenCode');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('__userId');
    localStorage.removeItem('redirectToPurchase');
    localStorage.removeItem('combinedId');
    setIsLogin(localStorage.removeItem('isLogin'));
    if (localStorage.getItem('isLogin') === null) {
      navigate('/login');
    }
  };

  const handalerChanges = async (e) => {
    const { name, value } = e.target;
    const header = localStorage.getItem('_tokenCode');

    if (name === 'country') {
      setCountryData(parseInt(value));
      const stateresponse = await API.allState(value, header);
      setAllStateData(stateresponse.data.data);
      setAllCityData(null);
      setFormData({
        ...formData,
        country: value,
        state: null,
        city: null,
      });
    } else if (name === 'state') {
      setStateData(parseInt(value));
      const cityresponse = await API.allCity(value, header);
      setAllCityData(cityresponse.data.data);
      setFormData({
        ...formData,
        state: value,
        city: null,
      });
    } else if (name === 'city') {
      setCityData(parseInt(value));
      setFormData({
        ...formData,
        city: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const copyfile = (data) => {
    // MESSAGE('Copied link', 1);
    navigate(`/sharable-link?key=${data.key}`);
    // window.open(`/sharable-link?key=${data.key}`);
    // navigator.clipboard.writeText(IMG + data);
  };

  const handleDownloadClick = (downloadLink, name) => {
    fetch(downloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary anchor element
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        let formattedName = name.replace(/^\s+|\s+$/g, '').replace(/\s+/g, '-');
        formattedName += '.mp3';
        link.setAttribute('download', formattedName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error downloading file:', error));
  };

  const historyMusicButton = (data, id) => {
    setHistoySong(data);
    setCurrentPlay(id);
  };

  const deletePastOrder = async (orderId) => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.deletePastHistroy(orderId, header);
      if (response.data.success === 1) {
        userDataGetById();
        MESSAGE(response.data.message, 1);
      }
    } catch (error) {
      console.error('Error deleting audio merge:', error);
    }
  };

  const handleEditIconClick = (item) => {
    setIsPopupOpen(true);
    setEditId(item.id);
    const token = localStorage.getItem('_tokenCode');
    if (token) {
      get_card_by_id(item?.key, token);
    }
  };

  const get_card_by_id = async (uuidKey, token) => {
    try {
      const response = await API.get_greeting_card_id(uuidKey, token);
      if (response.status === 200) {
        setTitle(response?.data?.title ?? response?.data?.songname);
        setImgSrc(IMG + (response.data.image_logo ?? response?.data?.songimage));
      } else {
        console.log('Error: ', response.data.message);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'title') {
      setTitle(value);
    }
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageFile = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            async (blob) => {
              const compressedFile = new File([blob], imageFile.name, { type: 'image/jpeg' });
              const compressedDataURL = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve(reader.result);
                };
                reader.readAsDataURL(compressedFile);
              });
              setImgSrc(compressedDataURL);
            },
            'image/jpeg',
            0.9
          ); // Adjust quality as needed
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleSave = async () => {
    if (!title.trim() && !imgSrc) {
      return toast.error('At least one field must be filled');
    }
    const formData = new FormData();
    formData.append('title', title);
    if (imgSrc) {
      const originalBlob = await fetch(imgSrc).then((res) => res.blob());
      formData.append('image_logo', originalBlob, 'original.jpg');
    }
    try {
      const header = localStorage.getItem('_tokenCode');
      const response = await API.patch_greeting_card(editId, formData, header);
      if (response.status === 200) {
        setIsPopupOpen(false);
        toast.success('Added successfully');
      } else {
        console.log('Error: ', response.data.message);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleDeleteIconClick = (orderId) => {
    setOrderIdToDelete(orderId);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    deletePastOrder(orderIdToDelete);
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setOrderIdToDelete(null);
  };

  return (
    <>
      <div className='ms_genres_wrapper dashboard mbxy'>
        <div className='row align-items-center'>
          <div className='col-md-12'>
            {/* <div className='profile-top'>
              <div className='dashboard_img'>
                <img src='https://cdn-icons-png.flaticon.com/512/3237/3237472.png' className='w-100' />
              </div>
            </div> */}
            <div className='profile-top'>
              <div className='dashboard_img'>
                <img src={formData.profile_image ? IMG + formData.profile_image : imageSrc} className='profile-img' alt='Profile' />
                <label htmlFor='imageUpload' className='edit-icon' title='Upload Image'>
                  <i className='bi bi-pencil-square' style={{ fontSize: '25px' }}></i>
                </label>
                <input type='file' id='imageUpload' accept='image/*' onChange={handleImageUpload} style={{ display: 'none' }} />
              </div>
            </div>
            <div className='profile-bottom'>
              <div className='lgotbtn text-end'>
                <span className='logout' onClick={logout}>
                  <i className='fa fa-sign-out'></i>
                </span>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <h1>{formData.name}</h1>
                  {/* <p>
                    <i className="bi bi-globe"></i> {formData.city},{formData.state}{" "}
                    ,{formData.country}
                  </p> */}
                  {/* <h6>
                    <i className="bi bi-person-fill"></i> Lead Hip Hop Singer at
                    Lorem Ipsum
                  </h6> */}
                </div>
                <div className='col-md-6 text-end'>
                  <div className='sclntwrk'>
                    <ul>
                      <li>
                        <p className='m-0'>
                          <i className='fa fa-envelope-o' aria-hidden='true'></i> {formData.email}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='maindashboardbdy'>
                    <ul className='nav nav-tabs' id='myTab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link active'
                          id='profile-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#profile'
                          type='button'
                          role='tab'
                          aria-controls='profile'
                          aria-selected='false'
                        >
                          Edit Profile
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link'
                          id='history-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#history'
                          type='button'
                          role='tab'
                          aria-controls='history'
                          aria-selected='false'
                        >
                          History
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link'
                          id='password-tab'
                          data-bs-toggle='tab'
                          data-bs-target='#password'
                          type='button'
                          role='tab'
                          aria-controls='password'
                          aria-selected='false'
                        >
                          Change Password
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content text-center' id='myTabContent'>
                      <div className='tab-pane' id='dashboard' role='tabpanel' aria-labelledby='dashboard-tab'></div>
                      <div className='tab-pane fade show text-center active' id='profile' role='tabpanel' aria-labelledby='profile-tab'>
                        <EditProfile
                          handalerChanges={handalerChanges}
                          formData={formData}
                          allCountryData={allCountryData}
                          allStateData={allStateData}
                          allCityData={allCityData}
                          getUserData={getUserData}
                          countryData={countryData}
                          stateData={stateData}
                          cityData={cityData}
                          userDataGetById={userDataGetById}
                        />
                      </div>
                      <div className='tab-pane fade' id='history' role='tabpanel' aria-labelledby='history-tab'>
                        <div className='album_inner_list'>
                          <div className='album_list_wrapper history_tab'>
                            {orderData?.length === 0 ? (
                              <div style={{ paddingTop: '25px' }}>
                                <span className='p-0' style={{ fontSize: '25px' }}>
                                  No data found
                                </span>
                              </div>
                            ) : (
                              <table className='table'>
                                <thead>
                                  <tr>
                                    {/* <th>Song Name</th> */}
                                    <th>Song Title</th>
                                    <th>Duration</th>
                                    <th className='text-center'>Sharable Link</th>
                                    <th className='text-center'>Download</th>
                                    <th className='text-center'>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orderData?.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item.is_paid ? (
                                          <Link to='#' onClick={() => historyMusicButton(item.combined, item.id)}>
                                            {currentPlay === item.id ? (
                                              <img className='playIcon' src='https://m.media-amazon.com/images/G/01/digital/music/player/web/EQ_accent.gif' />
                                            ) : (
                                              <span className='play_hover'></span>
                                            )}
                                          </Link>
                                        ) : (
                                          'N/A'
                                        )}
                                        <a href='#'>{item.templatetitle}</a>
                                      </td>
                                      {/* <td>
                                        <a href='#'>{item.templatetitle}</a>
                                      </td> */}
                                      <td>
                                        <a href='#'>{item.combined_duration}</a>
                                      </td>
                                      <td className='text-center'>
                                        {item.is_paid ? (
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <span onClick={() => copyfile(item)} className='trash' style={{ marginRight: '15px' }}>
                                              <i className='bi bi-share text-white'></i>
                                            </span>
                                            <span className='trash' onClick={() => handleEditIconClick(item)}>
                                              <i className='bi bi-pencil-square text-white'></i>
                                            </span>
                                          </div>
                                        ) : (
                                          'N/A'
                                        )}
                                      </td>
                                      <td className='text-center'>
                                        {item.is_paid ? (
                                          <a href='javascript:void(0)' className='trash' onClick={(e) => handleDownloadClick(IMG + item.combined, item.templatetitle)}>
                                            <i className='bi bi-download text-white'></i>
                                          </a>
                                        ) : (
                                          <a href='#!' className='trash'>
                                            <i className='bi bi-stopwatch text-white'></i>
                                          </a>
                                        )}
                                      </td>
                                      <td className='text-center'>
                                        <span className='trash' onClick={() => handleDeleteIconClick(item.id)}>
                                          <i className='bi bi-trash3'></i>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                        <div className={histoySong ? 'row' : 'd-none'}>
                          <div className='col-md-12 historyPlay'>
                            <AudioPlayer autoPlay={false} src={IMG + histoySong} />
                          </div>
                        </div>
                      </div>
                      <div className='tab-pane fade' id='password' role='tabpanel' aria-labelledby='password-tab'>
                        <ChangesPassword />
                      </div>
                      <div className='tab-pane fade' id='songs' role='tabpanel' aria-labelledby='songs-tab'>
                        <DownloadMusic />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content'>
            <span className='sharable-close' onClick={handleClosePopup}>
              &times;
            </span>
            <div className='input-group'>
              <label htmlFor='imageUpload'>Upload Image:</label>
              <input type='file' id='imageUpload' name='image_logo' accept='image/*' onChange={onSelectFile} />
            </div>

            {!!imgSrc && (
              <>
                <div className='fixed-img text-center' style={{ height: '383px' }}>
                  <img
                    src={imgSrc}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              </>
            )}
            <div className='input-group'>
              <label htmlFor='textInput'>Enter Text:</label>
              <input type='text' id='textInput' name='title' value={title} onChange={handleChange} />
            </div>
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content text-center' style={{ width: '500px' }}>
            <span className='sharable-close' onClick={handleCancelDelete}>
              &times;
            </span>
            <p style={{ margin: '20px 0', color: '#000000' }}>
              Do you really want to delete your purchase order history? This will remove your ability to download and share your songs.
            </p>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='border-0' style={{ marginRight: '5px' }} onClick={handleConfirmDelete}>
                OK
              </button>
              <button className='border-0' onClick={handleCancelDelete}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyAccount;
