import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as API from '../api/index';
import { MESSAGE } from '../schemas/Validation';
import { IMG } from '../api/constant';
import check from '../assets/images/check.png';
import loader from '../assets/images/loader.gif';
import { toast } from 'react-toastify';
const ThankYou = () => {
  const [music, setMusic] = useState([]);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editId, setEditId] = useState('');
  const [title, setTitle] = useState('');
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    const getQueryParameter = (name) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get(name);
    };
    const paymentId = getQueryParameter('paymentId');
    const payerId = getQueryParameter('PayerID');

    const paymentCheck = async () => {
      const header = localStorage.getItem('_tokenCode');
      try {
        const reqObj = {
          paymentId: paymentId,
          PairID: payerId,
        };
        console.log('reqObj', reqObj);
        const response = await API.payment_check(reqObj, header);
        if (response.data.success === 1) {
          //MESSAGE(response.data.msg, 1);
          setMusic(response.data.data);
        } else {
          navigate('/');
          //MESSAGE(response.data.msg);
        }
      } catch (error) {}
    };
    paymentCheck();
  }, []);

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    console.log('value: ', value);
    console.log('name: ', name);
    if (name === 'title') {
      setTitle(value);
    }
  };
  const handleEditIconClick = (item) => {
    setIsPopupOpen(true);
    setEditId(item.id);
    const token = localStorage.getItem('_tokenCode');
    if (token) {
      get_card_by_id(item?.key, token);
    }
  };

  const get_card_by_id = async (uuidKey, token) => {
    try {
      const response = await API.get_greeting_card_id(uuidKey, token);
      if (response.status === 200) {
        setTitle(response?.data?.title ?? response?.data?.songname);
        setImgSrc(IMG + (response.data.image_logo ?? response?.data?.songimage));
      } else {
        console.log('Error: ', response.data.message);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const copyfile = (data) => {
    // MESSAGE('Copied link', 1);
    navigate(`/sharable-link?key=${data.key}`);
    // window.open(`/sharable-link?key=${data.key}`, '_blank');
    // navigator.clipboard.writeText(IMG + data);
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageFile = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            async (blob) => {
              const compressedFile = new File([blob], imageFile.name, { type: 'image/jpeg' });
              const compressedDataURL = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve(reader.result);
                };
                reader.readAsDataURL(compressedFile);
              });
              setImgSrc(compressedDataURL);
            },
            'image/jpeg',
            0.9
          ); // Adjust quality as needed
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleSave = async () => {
    if (!title.trim() && !imgSrc) {
      return toast.error('At least one field must be filled');
    }
    const formData = new FormData();
    formData.append('title', title);
    if (imgSrc) {
      const originalBlob = await fetch(imgSrc).then((res) => res.blob());
      formData.append('image_logo', originalBlob, 'original.jpg');
    }
    try {
      const header = localStorage.getItem('_tokenCode');
      const response = await API.patch_greeting_card(editId, formData, header);
      if (response.status === 200) {
        setIsPopupOpen(false);
        toast.success('Added successfully');
      } else {
        console.log('Error: ', response.data.message);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleDownloadClick = (downloadLink, name) => {
    console.log('downloadLink: ', downloadLink);
    fetch(downloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary anchor element
        const url = window.URL.createObjectURL(new Blob([blob]));
        console.log('url: ', url);
        const link = document.createElement('a');
        console.log('link: ', link);
        link.href = url;
        let formattedName = name.replace(/^\s+|\s+$/g, '').replace(/\s+/g, '-');
        formattedName += '.mp3';
        link.setAttribute('download', formattedName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error downloading file:', error));
  };

  return (
    <>
      <section class='thankyousec py-5'>
        <div class='container'>
          <div class='row justify-content-evenly'>
            <div class='col-md-11'>
              {music.length === 0 ? (
                <>{/* <img src={loader} /> */}</>
              ) : (
                <>
                  <div class='thnkbx text-center'>
                    <div class='thnkimg'>
                      <img src={check} />
                    </div>
                    <h1>Thank You!</h1>
                    <h4 class='pb-2'>Your Order has been placed</h4>
                    <div class='cart'>
                      <div class='table-responsive'>
                        <table class='table'>
                          <thead class='thead-darks'>
                            <tr>
                              <th scope='col' class='text-white'>
                                Id
                              </th>
                              <th scope='col' class='text-white'>
                                Order Name
                              </th>
                              <th scope='col' class='text-white'>
                                File
                              </th>
                              <th scope='col' class='text-white'>
                                Sharable Link
                              </th>
                              <th scope='col' class='text-white'>
                                Download
                              </th>

                              {/* <th scope="col" class="text-white">
                                  Action
                                </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {music.map((item, index) => (
                              <tr key={index} className='align-middle'>
                                <td>
                                  <h6>{index + 1}</h6>
                                </td>
                                <td>
                                  <h6>{item.templatename}</h6>
                                </td>
                                <td>
                                  <h6>
                                    <audio controls src={IMG + item.musicfile}></audio>
                                  </h6>
                                </td>
                                <td>
                                  {item.is_paid ? (
                                    <>
                                      <li className='d-flex justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <span onClick={() => copyfile(item)} className='trash' style={{ marginRight: '15px' }}>
                                            {/* <i className='bi bi-clipboard-fill'></i> */}
                                            <i class='bi bi-share text-white'></i>
                                          </span>
                                          <span className='trash' onClick={() => handleEditIconClick(item)}>
                                            <i className='bi bi-pencil-square text-white'></i>
                                          </span>
                                        </div>
                                      </li>
                                    </>
                                  ) : (
                                    <li> N/A</li>
                                  )}
                                </td>
                                <td>
                                  <li className='d-flex justify-content-center'>
                                    {item.is_paid ? (
                                      <a
                                        href='#!'
                                        // className='ms_btn'
                                        className='trash'
                                        // style={{
                                        //   background: 'green',
                                        //   color: 'white',
                                        //   padding: ' 0 10px',
                                        //   fontSize: '20px',
                                        // }}
                                        onClick={(e) => handleDownloadClick(IMG + item.musicfile, item.templatename)}
                                      >
                                        <i className='bi bi-download text-white'></i>
                                      </a>
                                    ) : (
                                      <a
                                        href='#!'
                                        // style={{
                                        //   background: 'red',
                                        //   color: 'white',
                                        //   padding: ' 0 10px',
                                        //   fontSize: '20px',
                                        // }}
                                        className='trash'
                                      >
                                        <i className='bi bi-stopwatch text-white'></i>
                                      </a>
                                    )}
                                  </li>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <ul className="productfile">
                        {data.map((item, index) => (
                          <li className="align-items-center d-flex justify-content-center">
                            <span className="countNum">{index + 1}</span>
                            <span>
                              <audio controls src={IMG + item.musicfile}></audio>
                            </span>
                          </li>
                        ))}
                      </ul> */}
                  </div>
                  <div class='col-md-12' style={{ marginTop: '5em' }}>
                    <div class='btnprt text-center mt-5'>
                      <Link to='/song-list' class='ms_btn'>
                        Back to Song List
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div class='col-lg-7 d-none'>
              <h5 class='mb-4'>Order List</h5>
              <div class='grp_sec'>
                <div class='img_sec border_prt'>
                  <img src='assets/images/music.png' />
                </div>
                <div class='song_item border_prt'>
                  <h5>Alan Walker - Faded</h5>
                  <p>#415642534780</p>
                </div>
                <div class='border_prt'>
                  <p>$1.99</p>
                </div>
              </div>
            </div>
            <div class='col-lg-4 d-none'>
              <div class='right_sec'>
                <h5 class='mb-4'>Order Summary</h5>
                <div class='list_sec'>
                  <ul>
                    <li>
                      <span>Subtotal</span>
                      <span>$1.99</span>
                    </li>
                    <li>
                      <span>Subtotal</span>
                      <span>$1.99</span>
                    </li>
                    <li>
                      <span>Subtotal</span>
                      <span>$1.99</span>
                    </li>
                  </ul>
                </div>
                <div class='total_sec'>
                  <ul>
                    <li>Total</li>
                    <li>$8.63</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isPopupOpen && (
        <div className='sharable-popup'>
          <div className='sharable-popup-content'>
            <span className='sharable-close' onClick={handleClosePopup}>
              &times;
            </span>
            <div className='input-group'>
              <label htmlFor='imageUpload'>Upload Image:</label>
              <input type='file' id='imageUpload' name='image_logo' accept='image/*' onChange={onSelectFile} />
            </div>

            {!!imgSrc && (
              <>
                <div className='fixed-img text-center' style={{ height: '383px' }}>
                  <img
                    src={imgSrc}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              </>
            )}
            <div className='input-group'>
              <label htmlFor='textInput'>Enter Text:</label>
              <input type='text' id='textInput' name='title' value={title} onChange={handleChange} />
            </div>
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYou;
