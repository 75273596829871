import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as API from '../api/index';
import { IMG } from '../api/constant';

const Container = () => {
  const video = 'assets/wrappervideo.mp4';

  const [homeData, setHomeData] = useState('');

  const getHomepageData = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.homePageData(header);
      console.log('response: ', response);
      if (response?.data?.data) {
        setHomeData(response?.data?.data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getHomepageData();
  }, []);
  return (
    <>
      <div class='ms-banner'>
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-lg-12 col-md-12'>
              <div class='ms_banner_video'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <video
                  controls
                  playsinline
                  src=${IMG + homeData?.video}
                  class="loaderVideo"
                  />,
              `,
                  }}
                ></div>
                <h2 class='abotHead'>{homeData?.title}</h2>
                <p class='aboutWrap'>{homeData?.message}</p>
                <div class='text-center'>
                  <Link class='ms_btn' to='/song-list'>
                    Start Your Song
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Container;
