import React, { useContext, useEffect, useState } from 'react';
import InnerBanner from '../components/InnerBanner';
import * as API from '../api/index';
import { useLocation, useNavigate } from 'react-router';
import { IMG, NOIMG } from '../api/constant';
import { Link } from 'react-router-dom';
import { MESSAGE, TOKEN_CODE } from '../schemas/Validation';
import AudioTrack from '../components/AudioTrack';
import CommonCata from '../components/CommonCata';
import CataDetails from './CataDetails';
import MessagePlacePage from './MessagePlacePage';

const MusicTemplete = () => {
  const location = useLocation();

  const [musicIndex, setMusicIndex] = useState('');
  const [songData, setSongData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(songData[trackIndex]);

  const [cataGoriData, setCataGoriData] = useState([]);

  const musiaChoose = (index, songid) => {
    setIsPlaying(true);
    setMusicIndex(songid);
    setCurrentTrack(songData[index]);
    setTrackIndex(index);
  };

  const getVatagoriy_details = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.songWiseTempleteList(JSON.parse(localStorage.getItem('__musicData')).song_id, header);
      localStorage.setItem('_cataGorid', response.data.data.category_id);
      setCataGoriData(response.data.data);
      setCurrentTrack(response.data.data.music[trackIndex]);

      setSongData(response.data.data.music);
    } catch (error) {}
  };

  const add_music_user = (typeTemp, amount, messlength, item) => {
    console.log('item: ', item);
    try {
      const songObj = {
        temtype: typeTemp,
        amount: amount,
        duration: messlength,
        intro_outro_length: item.intro_outro_length,
      };
      localStorage.setItem('__template', JSON.stringify(songObj));
    } catch (error) {}
  };

  const handleAmountClick = (id) => {
    const selectedSong = songData.find((song) => song.id === id);
    if (selectedSong) {
      localStorage.setItem('__selectedSongName', JSON.stringify(selectedSong.name));
      // Add any other logic you need here for handling the amount click
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getVatagoriy_details();
  }, []);

  return (
    <>
      {/* <InnerBanner /> */}
      {/* <CataDetails /> */}
      <div className='ms_genres_wrapper'>
        <div className='row justify-content-center'>
          <div className='col-md-9'>
            <div className='album_single_data'>
              <div className='album_single_img'>
                <img src={cataGoriData.image === undefined ? NOIMG : IMG + cataGoriData.image} alt='' className='img-fluid' />
              </div>
              <div className='album_single_text'>
                <h2>{cataGoriData.name}</h2>
                {/* <p className="singer_name">
                  Category ,{" "}
                  <span className="commonColor">
                    {cataGoriData.category_name}
                  </span>
                </p> */}
                <div className='about_artist'>{cataGoriData.description}</div>
              </div>
            </div>

            <div className='album_inner_list'>
              <div className='album_list_wrapper mobileList'>
                <ul className='album_list_name'>
                  <li style={{ width: '8%' }}>Select</li>
                  <li style={{ width: '50%' }}>Sample Type</li>
                  <li style={{ width: '10%' }}>Amount</li>
                </ul>
                {songData.length === 0 ? (
                  <h1 className='noRechor'>No Data Found</h1>
                ) : (
                  songData.map((item, index) => (
                    <ul key={item.id}>
                      <li style={{ width: '8%' }} className={musicIndex === item.id ? 'songActive' : 'calll'} onClick={() => musiaChoose(index, item.id)}>
                        <Link
                          onClick={() => {
                            add_music_user(item.template_type_status, item.amount, item.messlength, item);
                            handleAmountClick(item.id); // Call handleAmountClick with the item's id
                          }}
                          to='/message-placement'
                          className='cart_btn'
                          state={{ tamId: item.id }}
                        >
                          <i className='fa fa-plus' aria-hidden='true'></i>
                        </Link>
                      </li>
                      <li style={{ width: '50%' }} className={musicIndex === item.id ? 'songActive' : 'calll'} onClick={() => musiaChoose(index, item.id)}>
                        <Link to='javascript:void(0)'>
                          {musicIndex === item.id ? (
                            <img className='playIcon' src='https://m.media-amazon.com/images/G/01/digital/music/player/web/EQ_accent.gif' />
                          ) : (
                            <span className='play_hover'></span>
                          )}
                        </Link>
                        <Link to='javascript:void(0)'>{item.name}</Link>
                      </li>
                      <li style={{ width: '10%' }} className={musicIndex === item.id ? 'songActive' : 'calll'} onClick={() => musiaChoose(index, item.id)}>
                        {item.amount === '' ? (
                          'Free'
                        ) : (
                          <>
                            <Link to='javascript:void(0)'>$ {item.amount}</Link>
                          </>
                        )}
                      </li>
                    </ul>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='ms_view_more padder_bottom20'>
          <Link className='ms_btn' to='/song-list'>
            Back
          </Link>
        </div>
      </div>
      {songData.length === 0 ? (
        ''
      ) : (
        <>
          <AudioTrack
            musiaChoose={musiaChoose}
            musicIndex={musicIndex}
            setMusicIndex={setMusicIndex}
            cataName={cataGoriData.category_name}
            tracks={songData}
            setTrackIndex={setTrackIndex}
            trackIndex={trackIndex}
            currentTrack={currentTrack}
            setCurrentTrack={setCurrentTrack}
            setIsPlaying={setIsPlaying}
            isPlaying={isPlaying}
          />
        </>
      )}
    </>
  );
};

export default MusicTemplete;
